<!--
 * @Author: lbh
 * @Date: 2021-04-23 14:32:14
 * @LastEditors: lbh
 * @LastEditTime: 2021-04-30 18:55:55
 * @Description: 變頻滾動的背景
-->
<template>
  <div
    class="background-box"
    :style="`height:${backH}px;`"
  >
    <!-- 蒙層 和 標題 -->
    <div class="mask-box">
      <div
        class="item_1 slideInDown animated_1 infinite"
        v-if="type == 1"
      >
        <div
          class="title"
          v-text="title"
        ></div>
        <div
          class="content"
          v-text="content"
        ></div>
      </div>
      <div
        class="item_2 slideInDown animated_1 infinite"
        :class="wtype != '1000' ? 'item_2_1' : ''"
        :style="`${type==3 ? `align-items: center;` : ``}`"
        v-else-if="type == 2 || type == 3"
      >
        <div
          class="title"
          v-text="title"
        ></div>
        <div
          class="content"
          v-text="content"
        ></div>
      </div>
      <div
        class="item_4 slideInDown animated_1 infinite"
        :class="wtype != '1000' ? 'item_4_1' : ''"
        v-else-if="type == 4"
      >
        <div
          class="title"
          v-text="title"
        ></div>
        <div
          class="content"
          v-text="content"
        ></div>
      </div>
      <div
        v-if="type == 1"
        class="up fadeInDown animated_1 infinite"
        @click="scrollTop"
      >
        <i class="el-icon-arrow-down pointer-hover"></i>
      </div>
    </div>
    <!-- 背景 -->
    <div
      class="img-box pulse animated_1 infinite"
      v-show="(bgImage && wtype == '1000') || (!bgImage) "
      :style="`background-image:url(${ image});`"
    ></div>
    <div
      class="img-box pulse animated_1 infinite"
      v-show="bgImage && wtype != '1000'"
      :style="`background-image:url(${ bgImage });`"
    ></div>
  </div>
</template>
<script>
import util from "@/js/utils/util";
import "@/store/animate.css";
import jquery from "jquery";
export default {
  name: "self-background",
  data () {
    return {

    }
  },
  mounted () {
    window.jquery = jquery;
  },
  methods: {
    scrollTop () {
      console.log(122)
      let box = jquery('.background-box')[0]
      let top = box.offsetTop;
      let h = box.offsetHeight;
      window.scrollTo({
        top: top + h
      })
    }
  },
  props: {
    backH: {
      default: 0
    },
    bh: {
      default: 0
    },
    // 圖片
    image: {
      default: ''
    },
    bgImage: {
      default: ''
    },
    title: {
      default: ''
    },
    content: {
      default: ''
    },
    type: {
      default: 0
    },
    wtype: {
      default: '1000'
    }
  }
}
</script>
<style lang="less" scoped>
.background-box {
  position: relative;
  overflow: hidden;
  .mask-box {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.3);
    .up {
      display: flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 40px;
        color: #fff;
      }
    }
    .item_1 {
      width: 100%;
      height: calc(100% - 100px);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 45px;
        color: #fff;
        font-weight: 700;
      }
      .content {
        font-size: 36px;
        color: #fff;
      }
    }
    .item_2,
    .item_4 {
      width: 100%;
      height: calc(100% - 100px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 70px;
        color: #fff;
        margin-left: 70px;
        font-weight: 700;
      }
      .content {
        font-size: 24px;
        color: #fff;
        margin-left: 70px;
        font-weight: inherit;
      }
    }
    .item_2_1 {
      align-items: center;
      .title {
        font-size: 24pt !important;
        margin: 0;
      }
    }
    .item_4_1 {
      height: 100%;
      .title {
        font-size: 24pt !important;
        margin-left: 20px !important;
      }
      .content {
        font-size: 12pt !important;
        margin-left: 20px !important;
      }
    }
  }
  .img-box {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    top: auto;
    background-size: cover;
  }
}
</style>
